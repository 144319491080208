/* Replace Daisy Drawer */
.drawer {
  display: grid;
  overflow: hidden;
}

:where(.drawer-toggle ~ .drawer-content) {
  height: inherit;
}
.drawer-toggle {
  height: 0;
  width: 0;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
}
.drawer-toggle ~ .drawer-content {
  z-index: 0;
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
}
.drawer-toggle ~ .drawer-side {
  max-height: 100vh;
  grid-row-start: 1;
  grid-column-start: 1;
  display: grid;
  overflow-x: hidden;
}
.drawer-toggle ~ .drawer-side > .drawer-overlay {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.drawer-toggle ~ .drawer-side > .drawer-overlay + * {
  z-index: 10;
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.drawer-toggle:checked ~ .drawer-side {
  isolation: isolate;
  overflow-x: hidden;
  overflow-y: auto;
}
.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  visibility: visible;
  opacity: 0.999999;
  --tw-bg-opacity: 0.4;
}
.drawer-toggle:checked ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 1024px) {
  .drawer-mobile {
    grid-auto-columns: max-content auto;
  }
  .drawer-mobile > .drawer-toggle ~ .drawer-content {
    height: auto;
  }
  @media (min-width: 1024px) {
    .drawer-mobile > .drawer-toggle ~ .drawer-content {
      grid-column-start: 2;
    }
    .drawer-mobile > .drawer-toggle ~ .drawer-side > .drawer-overlay {
      visibility: visible;
    }
    .drawer-mobile > .drawer-toggle ~ .drawer-side > .drawer-overlay + * {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
  }
  .drawer-mobile > .drawer-toggle ~ .drawer-side {
    overflow-y: auto;
  }
  .drawer-mobile > .drawer-toggle:checked ~ .drawer-content {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.drawer-toggle:checked ~ .drawer-content {
  --tw-translate-x: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
